import { SamplingPoint } from "./../types/samplingpoint.type";
import { RootState, SamplingPointState } from "./../types/state.type";
import { Module } from "vuex";

const initialState: SamplingPointState = {
  samplingPoint: {} as SamplingPoint,
};

export const samplingPointStore: Module<SamplingPointState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {
    getStations: (state: SamplingPointState) => state.samplingPoint,
  },

  mutations: {
    addSamplingPoint(state, newSamplingPoint: SamplingPoint) {
      state.samplingPoint = newSamplingPoint;
    },
  },
  actions: {},
};
