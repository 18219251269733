import { IdName } from "./../types";
import { Filter } from "./../types/filter.type";
import { Pagination } from "./../types/pagination.type";
import { Station } from "./../types/station.type";
import { errorHandlerDefault, responseHandlerDefault } from "./../utils/handler.util";
import axios from "./api";

/**
 * get stations page
 *
 * @param page
 * @param size
 * @param filter
 * @returns
 */
export const getStations = (
  page?: number,
  size?: number,
  filter?: Filter
): Promise<Pagination<Station>> => {
  const params: { page?: number; size?: number; filter?: Filter } = {};
  if (page) {
    params.page = page;
  }
  if (size) {
    params.size = size;
  }
  if (filter) {
    params.filter = filter;
  }

  return axios.get(`/station/getlist/`, { params }).then(responseHandlerDefault);
};

/**
 * get detail station
 * @param station_id
 * @returns
 */
export const getDetailStation = (station_id: string): Promise<Station> => {
  return axios.get<Station>(`/station/getDetail/${station_id}`).then(responseHandlerDefault);
};

/**
 * update configuration
 * @param station
 * @returns
 */
export const updateStationConfiguration = (station: Station): Promise<Station> => {
  return axios
    .put(`/station/putConfiguration/${station.station}`, station)
    .then(responseHandlerDefault);
};

/**
 * save station
 * @param station
 * @returns
 */
export const saveStation = (station: Station): Promise<Station> => {
  if (station.id) {
    return updateStation(station);
  } else {
    return createStation(station);
  }
};

/**
 * create station
 * @param station
 * @returns
 */
export const createStation = (station: Station): Promise<Station> => {
  return axios
    .post(`/station/new`, station)
    .then(responseHandlerDefault)
    .catch(errorHandlerDefault);
};

/**
 * update station
 * @param station
 * @returns
 */
export const updateStation = (station: Station): Promise<Station> => {
  return axios.put(`/station/${station.id}`, station).then(responseHandlerDefault);
};

/**
 * change status activation
 * @param stationId
 * @returns
 */
export const changeActiveStation = (stationId: number): Promise<void> => {
  return axios.put(`/station/${stationId}/change_active`).then(responseHandlerDefault);
};

export const getStation = (): Promise<Station[]> => {
  return axios.get<Station[]>(`/station/`).then(responseHandlerDefault);
};

export const getStationNames = (): Promise<IdName[]> => {
  return axios.get<IdName[]>("/station/names/").then(responseHandlerDefault);
};

export const getStationTypes = (): Promise<IdName[]> => {
  return axios.get(`/station/station-types`).then(responseHandlerDefault);
};

export const getStationNewName = (): Promise<string> => {
  return axios.get<string>(`/station/newname`).then(responseHandlerDefault);
};

export const getStationNewId = (): Promise<string> => {
  return axios.get<string>(`/station/newid`).then(responseHandlerDefault);
};

export const getStationsSelects = (
  page?: number,
  size?: number,
  filter?: Filter
): Promise<Pagination<Station>> => {
  const params: { page?: number; size?: number; filter?: Filter } = {};
  if (page) {
    params.page = page;
  }
  if (size) {
    params.size = size;
  }
  if (filter) {
    params.filter = filter;
  }

  return axios.get(`/station/liststations`, { params }).then(responseHandlerDefault);
};
