import { Pagination } from "./../types/pagination.type";
import { User } from "./../types/user.type";

import axios from "./api";

/**
 *
 * @returns
 */
export const postUser = (user: User): Promise<User> => {
  return axios
    .post<User>(`/user/new`, user)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      throw new Error(res.status.toString());
    })
    .catch((res) => {
      throw new Error(res.status);
    });
};

export const getUserPage = (offset: number, elements = 10): Promise<Pagination<User>> => {
  return axios
    .get<Pagination<User>>(`/user/page/${offset}/${elements}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      throw new Error(res.status.toString());
    })
    .catch((res) => {
      throw new Error(res.status);
    });
};

export const changeActivateUser = (userId: number) => {
  return axios
    .put(`/user/${userId}/change_active`)
    .then((res) => {
      if (res.status === 204) {
        return res.data;
      }
      throw new Error(res.status.toString());
    })
    .catch((res) => {
      throw new Error(res.status);
    });
};

export const searchUser = (page: number, limit = 10, user: string): Promise<Pagination<User>> => {
  return axios
    .get<Pagination<User>>(`/user/search?page=${page}&limit=${limit}&user=${user}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      throw new Error(res.status.toString());
    })
    .catch((res) => {
      throw new Error(res.status);
    });
};

export const getUserById = (id: number): Promise<User> => {
  return axios
    .get<User>(`/user/${id}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      throw new Error(res.status.toString());
    })
    .catch((res) => {
      throw new Error(res.status);
    });
};

export const putUser = (id: number, modifiedUser: User): Promise<User> => {
  return axios
    .put<User>(`/user/${id}`, modifiedUser)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      throw new Error(res.status.toString());
    })
    .catch((res) => {
      throw new Error(res.status);
    });
};

export const getUserByEmail = (email: string | undefined): Promise<User> => {
  return axios
    .post<User>("/user/email", { email })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      throw new Error(res.status.toString());
    })
    .catch((err) => {
      throw new Error(err.response.data.message || err.message);
    });
};

export const updateUserPassword = (
  user_id: number | undefined,
  newPassword: string | undefined
): Promise<User> => {
  return axios
    .post<User>("/user/updatePass", { user_id, newPassword })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      throw new Error(res.status.toString());
    })
    .catch((err) => {
      throw new Error(err.response.data.message || err.message);
    });
};
export const getMenu = (user_id: number | undefined) => {
  return axios
    .get(`/user/menu/${user_id}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      throw new Error(res.status.toString());
    })
    .catch((err) => {
      throw new Error(err.response.data.message || err.message);
    });
};

interface AvailableRoutesPayload {
  userId: number;
  availableRoutes: string[];
}

export const saveAvailableRoutes = (
  payload: AvailableRoutesPayload
): Promise<AvailableRoutesPayload> => {
  return axios
    .post<AvailableRoutesPayload>(`/user/available-routes`, payload)
    .then((res) => {
      if (res.status === 201) {
        return res.data;
      }
      throw new Error(`Error: ${res.status}`);
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const getAvailableRoutes = (userId: number): Promise<string[]> => {
  return axios
    .get(`/user/available-routes/${userId}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data.availableRoutes;
      }
      throw new Error(`Error: ${res.status}`);
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};
