import { Station } from "./../types/station.type";
import { RootState, StationState } from "./../types/state.type";
import { Module } from "vuex";
import { getStationsSelects } from "@/service/station.service";

const initialState: StationState = {
  stations: [],
  station: {} as Station,
};

export const stationStore: Module<StationState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {
    getStations: (state: StationState) => state.stations,
    getStation: (state: StationState) => state.station,
  },

  mutations: {
    addStationsSelects(state, newStations: Station[]) {
      state.stations = newStations;
    },
    setStation(state, newStation: Station) {
      state.station = newStation;
      localStorage.setItem("station", JSON.stringify(newStation));
    },
  },

  actions: {
    // Action para agregar estaciones
    async addStationsSelects({ commit }) {
      const liststation = await getStationsSelects();

      commit("addStationsSelects", liststation.data);
    },
  },
};
