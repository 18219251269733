import { App } from "vue";
import { createI18n, LocaleMessages, VueMessageType } from "vue-i18n";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  const locales = require.context("../i18n", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages<VueMessageType> = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

export const i18n = createI18n({
  locale: navigator.language.includes("en") ? "en" : "es",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "es",
  messages: loadLocaleMessages(),
  datetimeFormats: {
    es: {
      date: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      dateTime: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      },
    },
  },
  numberFormats: {
    en: {
      default: {
        style: "decimal",
        maximumFractionDigits: 20,
      },
      fixed: {
        style: "decimal",
        maximumFractionDigits: 2,
      },
    },
    es: {
      default: {
        style: "decimal",
        maximumFractionDigits: 20,
        useGrouping: true,
      },
      fixed: {
        style: "decimal",
        maximumFractionDigits: 2,
        useGrouping: true,
      },
    },
  },
});
export default (app: App): void => {
  app.use(i18n);
};
