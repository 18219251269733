import { hasNotifications } from "./../service/generatedAlert.service";
import { AppType } from "./../types/app.type";
import { RootState, UiState } from "./../types/state.type";
import { Module } from "vuex";

const initialState: UiState = {
  sidebarOpen: true,
  app: undefined,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  window: null,
  notifications: 0,
};

export const uiStore: Module<UiState, RootState> = {
  namespaced: true,
  state: initialState,
  actions: {
    checkNotifications(context) {
      hasNotifications(60).then((number) => {
        context.commit("setNotifications", number);
      });
    },
  },
  mutations: {
    sidebarToggle(state, open?: boolean): void {
      state.sidebarOpen = open !== undefined ? open : !state.sidebarOpen;
    },
    setApp(state, app: AppType | undefined): void {
      state.app = app;
    },
    setWindow(state, size: { width: number; height: number }): void {
      const mobile = size.width < 991;

      if (!state.window?.mobile && mobile) {
        state.sidebarOpen = false;
      }

      state.window = {
        height: size.height,
        width: size.width,
        mobile,
      };
    },
    setNotifications(state, notifications: number): void {
      state.notifications = notifications;
    },
  },
};
