import { i18n } from "./../plugins/i18n.plugin";
import { AppError } from "./../types/app.type";
import { AxiosResponse } from "axios";
import { ElMessage } from "element-plus";

type ValidatorError = { [key: string]: { message: string; field: string; fieldValue: unknown }[] };

function isValidatorError(obj: ValidatorError): obj is ValidatorError {
  const [firstProp] = Object.keys(obj);
  return obj[firstProp] && obj[firstProp][0] && obj[firstProp][0].message !== undefined;
}

export const errorHandlerDefault = (err: unknown): void => {
  let key = err as string;

  if (err instanceof Error) {
    key = err.message;
  } else if (isValidatorError(err as ValidatorError)) {
    key = AppError.FORM_VALIDATION;
  }
  key = `error.${key}`;

  let message = i18n.global.t(key);
  if (key === message) {
    key = "error.any";
    message = i18n.global.t(key);
    if (key === message) {
      message = "Se ha producido un error";
    }
  }
  ElMessage.error(message);
  // if (process.env.NODE_ENV === "development") {
  // eslint-disable-next-line no-console
  console.error("errorHandlerDefault", err);
  // }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const responseHandlerDefault = (res: AxiosResponse): any => {
  if (res.status === 200) {
    return res.data;
  }
};

export const responseHandlerUpload = (res: AxiosResponse): any => {
  if (res.status == 200) {
    return res.data;
  }
};
