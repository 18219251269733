import { createStore } from "vuex";
import { authStore } from "./auth.store";
import { uiStore } from "./ui.store";
import { stationStore } from "./station.store";
import { samplingPointStore } from "./samplingpoint.store";

export default createStore({
  modules: {
    auth: authStore,
    ui: uiStore,
    station: stationStore,
    samplingpoint: samplingPointStore,
  },
});
