import { UserAccess } from "./../types/auth.type";
import { errorHandlerDefault } from "./../utils/handler.util";
import axios from "./api";
import TokenService from "./token.service";

/**
 * do login
 * @param username
 * @param password
 * @returns
 */
export const logIn = (email: string, password: string): Promise<UserAccess> => {
  return axios.post<UserAccess>(`/auth/login`, { email, password }).then((response) => {
    if (response.status !== 200) {
      throw new Error("INVALID_CREDENTIALS");
    }

    if (response.data.accessToken) {
      TokenService.setUser(response.data);
    }
    return response.data;
  });
};

/**
 * do logOut
 * @param userAccess
 * @param revoke
 */
export const logOut = async (userAccess: UserAccess | null, revoke = true): Promise<void> => {
  // - revoke token
  if (userAccess && revoke) {
    await axios
      .post<UserAccess>(`/auth/revokeToken`, { requestToken: userAccess.refreshToken })
      .catch(errorHandlerDefault);
  }

  TokenService.removeUser();
};
