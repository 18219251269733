import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import installElementPlus from "./plugins/element.plugin";
import installi18n from "./plugins/i18n.plugin";
import { setupAxiosInterceptor } from "./config/axios-interceptor.config";
import { setupGlobalError } from "./config/global-error.config";

setupAxiosInterceptor(store);

const app = createApp(App);

installi18n(app);
installElementPlus(app);

app.use(router).use(store);

setupGlobalError(app);

app.mount("#app");
