
import LayoutDefault from "@/components/layouts/LayoutDefaultComponent.vue";
import { defineComponent, ref, shallowRef } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  setup() {
    const route = ref(useRoute());
    const layout = shallowRef(LayoutDefault);
    const loaded = ref(false);
    return {
      route,
      layout,
      loaded,
    };
  },
  mounted() {
    this.loaded = true;
  },
  computed: {
    sidebarProps() {
      const props: { [key: string]: unknown } = {};
      if (this.route.meta.sidebar) {
        props.sidebar = this.route.meta.sidebar;
      }
      return props;
    },
    layoutName(): string {
      return this.route.meta.layout as string;
    },
  },
  watch: {
    layoutName: {
      immediate: true,
      async handler(layoutName) {
        this.loaded = false;
        try {
          const component = await import(`@/components/layouts/${layoutName}Component.vue`);
          this.layout = component?.default || LayoutDefault;
        } catch (e) {
          this.layout = LayoutDefault;
        }
        this.loaded = true;
      },
    },
  },
});
