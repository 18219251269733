import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    shadow: "never",
    class: "app-form-item"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        model: _ctx.form,
        rules: _ctx.rules,
        "label-width": "120px",
        ref: "passForm"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                prop: "password",
                label: _ctx.$t('label.password')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.form.password,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.password) = $event)),
                    type: "password",
                    autocomplete: "off"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                prop: "password",
                label: _ctx.$t('label.repeatPassword')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.form.passwordRepeat,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.passwordRepeat) = $event)),
                    type: "password",
                    autocomplete: "off"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }))
}