export enum AppType {
  "ADMIN" = "Administración",
  "SUPPORT" = "Servicio de apoyo a la toma de decisiones",
  "STATIONS" = "Servicio de gestión de estaciones",
}

export enum AppRole {
  USER_QUALITY_VIEW = 1, // Usuario Calidad visualizador
  USER_QUALITY_CONFIG = 2, // Usuario Calidad Configurador
  USER_QUALITY_AGENT = 3, // Usuario Calidad Agente de campo
  USER_TECH_VIEW = 4, // Usuario técnico gestión de estaciones visualizador
  USER_TECH_CONFIG = 5, // Usuario técnico gestión de estaciones configurador
  USER_TECH_OPERATOR = 6, // Usuario técnico gestión de estaciones operación/mantenimiento campo
  ADMIN = 7, // Administrador
  ACCIONA = 8, // Acciona
}

export enum AppError {
  LOGIN_FAILED = "LOGIN_FAILED",
  SESION_EXPIRED = "SESION_EXPIRED",
  ACCESS_DENIED = "ACCESS_DENIED",
  FORM_VALIDATION = "FORM_VALIDATION",
}
