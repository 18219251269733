import { RouteRecordRaw } from "vue-router";

const sidebarName = "SidebarAdmin";

export const adminRoutes: RouteRecordRaw[] = [
  {
    path: "/admin",
    component: () => import("@/views/common/Home.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/admin/stations",
    component: () => import("@/views/admin/Stations.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/admin/stations/:stationid",
    component: () => import("@/views/admin/StationDetail.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/admin/containers",
    component: () => import("@/views/common/Container.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    name: "adminContainerDetail",
    path: "/admin/containers/:containerid",
    component: () => import("@/views/admin/ContainerEditDetail.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/admin/samplingpoints",
    component: () => import("@/views/common/SamplingPoints.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/admin/users",
    component: () => import("@/views/admin/Users.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/admin/parameters",
    component: () => import("@/views/admin/Parameter.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/admin/saica",
    component: () => import("@/views/admin/Saica.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/admin/saih",
    component: () => import("@/views/admin/Saih.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/admin/aemet",
    component: () => import("@/views/admin/Aemet.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/admin/generatedAlerts",
    component: () => import("@/views/common/GeneratedAlerts.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/admin/upload",
    component: () => import("@/views/support/UploadData.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/admin/downloadImages",
    component: () => import("@/views/admin/downloadImages.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/admin/downloadTrainingData",
    component: () => import("@/views/admin/downloadTrainingData.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  // {
  //   path: "/admin/downloadOpticalData",
  //   component: () => import("@/views/admin/downloadOpticalData.view.vue"),
  //   meta: {
  //     layout: "LayoutDefault",
  //     sidebar: sidebarName,
  //   },
  // },
  {
    path: "/admin/validation",
    component: () => import("@/views/admin/ValidateExample.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/admin/trainingdata",
    component: () => import("@/views/admin/TrainingDataForm.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
];
