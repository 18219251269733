import globalStore from "./../store";
import { AppRole, AppType } from "./../types/app.type";
import { RootState } from "./../types/state.type";
import { isArray } from "@vue/shared";
import { Store } from "vuex";

class SecurityService {
  public readonly LOGIN_PAGE = "/login";
  public readonly HOME_PAGE = "/home";

  private readonly PUBLIC_PATTERN = ["/", this.LOGIN_PAGE];

  private readonly ADMIN_PATTERN = ["/admin", /\/admin\/.*/];
  public readonly ADMIN_ROLES = [AppRole.ADMIN];

  private readonly SUPPORT_PATTERN = ["/support", /\/support\/.*/];
  public readonly SUPPORT_ROLES = [
    AppRole.ADMIN,
    AppRole.USER_QUALITY_VIEW,
    AppRole.USER_QUALITY_CONFIG,
    AppRole.USER_QUALITY_AGENT,
  ];

  private readonly STATIONS_PATTERN = ["/stations", /\/stations\/.*/];
  public readonly STATIONS_ROLES = [
    AppRole.ADMIN,
    AppRole.USER_TECH_VIEW,
    AppRole.USER_TECH_CONFIG,
    AppRole.USER_TECH_OPERATOR,
  ];

  constructor(private readonly store: Store<RootState>) {}

  /**
   * current user is logged
   * @returns
   */
  isLogged(): boolean {
    return !!this.store.state.auth.user;
  }

  /**
   * check current ap
   * @param app
   * @returns
   */
  isApp(app: AppType): boolean {
    return this.getApp() === app;
  }

  /**
   * get current app
   * @returns
   */
  getApp(): AppType | undefined {
    return this.store.state.ui.app;
  }

  /**
   * check if user has all roles
   * @param checkRoles
   * @returns
   */
  hasRole(checkRoles: AppRole[] | AppRole): boolean {
    if (!isArray(checkRoles)) {
      checkRoles = [checkRoles];
    }
    const userRoles = this.store.state.auth.user?.roles || [];
    let hasRoles;
    for (const role of checkRoles) {
      hasRoles = (hasRoles || true) && userRoles.includes(role);
      if (!hasRoles) {
        break;
      }
    }

    return hasRoles || false;
  }

  /**
   * check if user has any role
   * @param checkRoles
   * @returns
   */
  hasAnyRole(checkRoles: AppRole[] | AppRole): boolean {
    if (!isArray(checkRoles)) {
      checkRoles = [checkRoles];
    }

    const userRoles = this.store.state.auth.user?.roles || [];
    let hasRoles;
    for (const role of checkRoles) {
      hasRoles = userRoles.includes(role);
      if (hasRoles) {
        break;
      }
    }

    return hasRoles || false;
  }

  canAccessToPath(path: string): boolean {
    let canAccess = false;
    if (
      this.isPublicPath(path) ||
      (this.isCommonPath(path) && this.isLogged()) ||
      (this.isAdminPath(path) && this.canAccessToApp(AppType.ADMIN)) ||
      (this.isSupportPath(path) && this.canAccessToApp(AppType.SUPPORT)) ||
      (this.isStationsPath(path) && this.canAccessToApp(AppType.STATIONS))
    ) {
      canAccess = true;
    }

    return canAccess;
  }

  canAccessToApp(app: AppType) {
    if (!this.isLogged()) {
      return false;
    }

    let canAccess;
    switch (app) {
      case AppType.ADMIN:
        canAccess = this.hasAnyRole(this.ADMIN_ROLES);
        break;
      case AppType.SUPPORT:
        canAccess = this.hasAnyRole(this.SUPPORT_ROLES);
        break;
      case AppType.STATIONS:
        canAccess = this.hasAnyRole(this.STATIONS_ROLES);
        break;
      default:
        canAccess = false;
        break;
    }

    return canAccess;
  }

  private isPublicPath(path: string) {
    return this.isPatternPath(path, this.PUBLIC_PATTERN);
  }

  private isCommonPath(path: string) {
    return path === this.HOME_PAGE;
  }

  public isAdminPath(path: string) {
    return this.isPatternPath(path, this.ADMIN_PATTERN);
  }

  public isSupportPath(path: string) {
    return this.isPatternPath(path, this.SUPPORT_PATTERN);
  }

  public isStationsPath(path: string) {
    return this.isPatternPath(path, this.STATIONS_PATTERN);
  }

  private isPatternPath(path: string, pathPatterns: (string | RegExp)[]) {
    let isPattern;
    for (const pathPattern of pathPatterns) {
      if (pathPattern instanceof RegExp) {
        isPattern = pathPattern.test(path);
      } else {
        isPattern = pathPattern === path;
      }
      if (isPattern) {
        break;
      }
    }

    return isPattern || false;
  }
}

export default new SecurityService(globalStore);
