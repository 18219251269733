
import { AppError } from "@/types/app.type";
import { User } from "@/types/user.type";
import { defineComponent } from "@vue/runtime-core";
import { ElForm, ElMessage } from "element-plus";

export default defineComponent({
  props: {
    user: {
      type: Object as () => User,
      required: false,
    },
  },
  data() {
    return {
      userSelected: [] as number[],
      form: {
        password: "",
        passwordRepeat: "",
      },
      rules: {
        password: [
          {
            required: true,
            message: this.$t("error.required"),
            trigger: "blur",
          },
        ],
        passwordRepeat: [
          {
            required: true,
            message: this.$t("error.required"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async changePass() {
      await (this.$refs["passForm"] as InstanceType<typeof ElForm>).validate();
      if (this.form.password !== this.form.passwordRepeat) {
        ElMessage.error(this.$t("error.PASS_DONTMATCH"));
        throw new Error(AppError.FORM_VALIDATION);
      } else if (this.form.password.length < 8) {
        ElMessage.error(this.$t("error.pass_length"));
        throw new Error(AppError.FORM_VALIDATION);
      }
      return this.form.password;
    },
  },
});
