import * as AuthService from "./../service/auth.service";
import { UserAccess } from "./../types/auth.type";
import { AuthState, RootState } from "./../types/state.type";
import { Module } from "vuex";

let user;
try {
  user = JSON.parse(localStorage.getItem("user") || "") as UserAccess;
} catch (error) {
  user = null;
}

const initialState: AuthState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const authStore: Module<AuthState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {
    loggedIn: (state: AuthState) => state.status.loggedIn,
  },
  actions: {
    login({ commit }, credentials: { username: string; password: string }) {
      return AuthService.logIn(credentials.username, credentials.password)
        .then((authUser) => {
          commit("loginSuccess", authUser);
        })
        .catch(() => {
          commit("loginFailure");
        });
    },
    // logout({ commit, state }, params: { revoke: boolean } | undefined): void {
    //   AuthService.logOut(state.user, params?.revoke);
    //   commit("logout");
    // },
    async logout({ commit, state }, params: { revoke: boolean } | undefined): Promise<void> {
      await AuthService.logOut(state.user, params?.revoke);
      console.log("Cerrando sesión...");
      commit("logout");
    },
    refreshToken({ commit }, authUser: UserAccess): void {
      commit("refreshToken", authUser);
    },
  },
  mutations: {
    loginSuccess(state, authUser: UserAccess): void {
      state.status.loggedIn = true;
      state.user = authUser;
    },
    loginFailure(state): void {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state): void {
      state.status.loggedIn = false;
      state.user = null;
    },
    refreshToken(state, authUser: UserAccess): void {
      state.status.loggedIn = true;
      if (!state.user) {
        return;
      }
      state.user = authUser;
    },
  },
};
