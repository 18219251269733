import { UserAccess } from "./../types/auth.type";

class TokenService {
  private getAuthUser(): UserAccess | undefined {
    let user;
    try {
      user = JSON.parse(localStorage.getItem("user") || "");
    } catch (error) {
      user = undefined;
    }
    return user;
  }
  getLocalRefreshToken() {
    return this.getAuthUser()?.refreshToken;
  }

  getLocalAccessToken() {
    return this.getAuthUser()?.accessToken;
  }

  getUser() {
    return this.getAuthUser();
  }

  setUser(user: UserAccess) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem("user");
  }
}

export default new TokenService();
